import { useCreateRecord, useInvalidateQueries } from "api/apiHooks.js";
import { getJsonApiAdapter } from "api/apiService.js";
import { useDefaultMutationConfig } from "api/apiUtils.js";
import { useAuthContext } from "components/auth/AuthContextProvider.js";
import { useToast } from "@unite-us/app-components";

export const useCreateUserUpdatesByEmployee = (mutationConfig) => {
  const { authToken } = useAuthContext();
  const { addToast } = useToast();
  const defaultMutationConfig = useDefaultMutationConfig();
  const invalidateQueries = useInvalidateQueries();

  const jsonApiAdapter = getJsonApiAdapter(
    window.feConfig.CORE_API_BASE_URL + "/batch",
    authToken,
  );

  return useCreateRecord("employee_updates_by_employee_ids", jsonApiAdapter, {
    mutationConfig: {
      ...defaultMutationConfig,
      onSuccess: () => {
        invalidateQueries("batch_data");
        invalidateQueries("employees");
        addToast({
          message:
            "Batch user update job has been scheduled. View import center page to monitor the status of your job.",
          type: "success",
          dataTestId: "update-users-toast-success",
        });
      },
      onError: (error) => {
        addToast({
          message:
            error.response.data.error || "There was an error updating users",
          type: "error",
          dataTestId: "update-users-toast-error",
        });
      },
      ...mutationConfig,
    },
  });
};

export const useCreateUserUpdates = (mutationConfig) => {
  const { authToken } = useAuthContext();
  const { addToast } = useToast();
  const defaultMutationConfig = useDefaultMutationConfig();
  const invalidateQueries = useInvalidateQueries();

  const jsonApiAdapter = getJsonApiAdapter(
    window.feConfig.CORE_API_BASE_URL + "/batch",
    authToken,
  );

  return useCreateRecord("employee_updates", jsonApiAdapter, {
    mutationConfig: {
      ...defaultMutationConfig,
      onSuccess: () => {
        invalidateQueries("batch_data");
        invalidateQueries("employees");
        addToast({
          message:
            "Batch user update job has been scheduled. View import center page to monitor the status of your job.",
          type: "success",
          dataTestId: "update-users-toast-success",
        });
      },
      onError: (error) => {
        addToast({
          message:
            error.response.data.error || "There was an error updating users",
          type: "error",
          dataTestId: "update-users-toast-error",
        });
      },
      ...mutationConfig,
    },
  });
};
