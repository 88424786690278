export const constructUserUpdatesPayloadByEmployee = (
  employees,
  target_state,
  target_primary_role,
) => {
  const payload = { employees };

  if (target_state) {
    payload.target_state = target_state;
  } else if (target_primary_role) {
    payload.target_primary_role = target_primary_role;
  }
  return payload;
};
