import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Modal } from "@unite-us/app-components";
import { SelectField } from "@unite-us/ui";
import { capitalize } from "lodash";
import {
  useCreateUserUpdates,
  useCreateUserUpdatesByEmployee,
} from "api/core/userUpdateHooks";
import { constructUserUpdatesPayload } from "../utils/constructUserUpdatesPayload";
import { constructUserUpdatesPayloadByEmployee } from "../utils/constructUserUpdatesPayloadByEmployee";

const MassEditUserModal = ({
  modalRef,
  closeHandler,
  selectedUsersCount,
  primaryRoles,
  selectedRolesIds,
  providers,
  setSelectedEmployees,
  selectingById,
  selectedEmployees,
}) => {
  const [selectedField, setSelectedField] = useState(null);
  const [selectedUserStatus, setSelectedUserStatus] = useState(null);
  const [selectedPrimaryRole, setSelectedPrimaryRole] = useState(null);

  const { createRecord: createUserUpdates } = useCreateUserUpdates();
  const { createRecord: createUserUpdatesByEmployee } =
    useCreateUserUpdatesByEmployee();

  const optionsToEdit = [
    { label: "User Status", value: "user_status" },
    { label: "Primary Role", value: "primary_role" },
  ];

  const primaryRoleOptions =
    primaryRoles?.map((role) => ({
      label: role.name,
      value: role.id,
    })) || [];

  const onRequestCloseHandler = () => {
    setSelectedField(null);
    setSelectedUserStatus(null);
    setSelectedPrimaryRole(null);
    setSelectedEmployees([]);
    closeHandler();
  };

  const onClickUpdateHandler = async () => {
    const payload = selectingById
      ? constructUserUpdatesPayloadByEmployee(
          selectedEmployees.join(","),
          selectedUserStatus || null,
          selectedPrimaryRole || null,
        )
      : constructUserUpdatesPayload(
          providers,
          selectedRolesIds || null,
          selectedUserStatus || null,
          selectedPrimaryRole || null,
        );

    try {
      if (selectingById) {
        await createUserUpdatesByEmployee(payload);
      } else {
        await createUserUpdates(payload);
      }
    } catch (error) {
      console.error("Error updating users: ", error);
    } finally {
      setSelectedField(null);
      setSelectedUserStatus(null);
      setSelectedPrimaryRole(null);
      setSelectedEmployees([]);
      closeHandler();
    }
  };

  const Footer = () => (
    <div className="flex justify-end space-x-6">
      <Button
        id="mass-edit-user-close-btn"
        className="text-sm text-brand-blue rounded border solid border-med-pale-blue bg-white normal-case"
        label="Cancel"
        data-testid="mass-edit-user-close-btn"
        onClick={() => onRequestCloseHandler()}
      />
      <Button
        id="mass-edit-user-update-btn"
        className="text-sm text-white rounded border solid border-med-pale-blue bg-brand-blue normal-case"
        label="Update"
        data-testid="mass-edit-user-update-btn"
        disabled={!selectedPrimaryRole && !selectedUserStatus}
        onClick={() => onClickUpdateHandler()}
      />
    </div>
  );

  return (
    <>
      <Modal
        ref={modalRef}
        modalContentStyles="bg-medium-fill-grey"
        customSize={{ width: "500px", height: "500px" }}
        title={`Edit Employees (${selectedUsersCount})`}
        data-testid="user-update-modal"
        spinnerShow={false}
        onRequestClose={onRequestCloseHandler}
        showBorderRadius={true}
        disableScroll={true}
        modal
        actions={<Footer />}
      >
        <SelectField
          id="select-user-field"
          label="Select a user field to edit."
          options={optionsToEdit}
          searchEnabled={false}
          placeholder="Select User Field"
          onChange={(value) => {
            setSelectedField(value);
            setSelectedUserStatus(null);
            setSelectedPrimaryRole(null);
          }}
          value={selectedField}
          className="m-0"
        />
        {selectedField === "user_status" && (
          <SelectField
            id="select-user-status"
            label="Select the new value for the user field."
            options={[
              { label: "Active", value: "active" },
              { label: "Inactive", value: "inactive" },
            ]}
            searchEnabled={false}
            placeholder="Select User Status"
            onChange={(value) => setSelectedUserStatus(value)}
            value={selectedUserStatus}
            className="m-0"
          />
        )}
        {selectedField === "primary_role" && (
          <SelectField
            id="select-primary-role"
            label="Select the new value for the user field."
            options={primaryRoleOptions}
            searchEnabled={false}
            placeholder="Select Primary Role"
            onChange={(value) => setSelectedPrimaryRole(value)}
            value={selectedPrimaryRole}
            className="m-0"
          />
        )}
        {(selectedPrimaryRole || selectedUserStatus) && (
          <div className="bg-white border border-dark-border-blue p-4">
            <h2 className="text-lg font-semibold text-text-blue mb-4 text-center">
              Summary
            </h2>
            <p
              className="text-text-blue text-sm"
              data-testid="updating-users-text"
            >
              You&apos;re updating{" "}
              <span className="font-bold text-md">{selectedUsersCount}</span>{" "}
              users&apos;{" "}
              <span className="font-bold text-md">
                {selectedPrimaryRole ? "Primary Role" : "User Status"}
              </span>{" "}
              to{" "}
              <span className="font-semibold text-md">
                {selectedPrimaryRole
                  ? primaryRoles.find((role) => role.id === selectedPrimaryRole)
                      .name
                  : capitalize(selectedUserStatus)}
              </span>
              .
            </p>
            <p className="text-text-blue text-sm mt-4">
              Click Update to add this request to the queue. Requests are
              prioritized based on importance and the order they&apos;re
              received.
            </p>
          </div>
        )}
      </Modal>
    </>
  );
};

MassEditUserModal.propTypes = {
  modalRef: PropTypes.object.isRequired,
  closeHandler: PropTypes.func.isRequired,
  selectedUsersCount: PropTypes.number.isRequired,
  primaryRoles: PropTypes.array.isRequired,
  selectedRolesIds: PropTypes.string,
  providers: PropTypes.string,
  setSelectedEmployees: PropTypes.func.isRequired,
};

export default MassEditUserModal;
